import HttpService from '@/services/http.service'
import Vue from 'vue'

Vue.use(HttpService)

class UploadService {
  buildFormData (formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(Array.isArray(data) && !data.length)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    } else {
      const value = data === null ? '' : data
      formData.append(parentKey, value)
    }
  }

  jsonToFormData (data) {
    const formData = new FormData()
    this.buildFormData(formData, data)
    return formData
  }

  upload (form, url, onUploadProgress) {
    return Vue.prototype.$http.post(url, this.jsonToFormData(form), {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  patchPage (form, url, onUploadProgress) {
    return Vue.prototype.$http.post(url, this.jsonToFormData(form), {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }
}

export default new UploadService()
