import store from '../store'

const configuration = {
  key: 'FSC2H-9B1A6B5B2D4A1B2scesavznoD2B-8C-7gC7B7D6D5E4F3H3I3H2C2==',
  requestHeaders: {
    Authorization: `Bearer ${store.getters.GET_OAUTH.access_token}`
  },
  imageUploadURL: `${process.env.VUE_APP_API_URL}/api/upload`,
  fontSize: ['8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '30', '32', '60', '96'],
  fontFamily: {
    'Roboto,sans-serif': 'Roboto',
    'Oswald,sans-serif': 'Oswald',
    'Montserrat,sans-serif': 'Montserrat',
    "'Open Sans Condensed',sans-serif": 'Open Sans Condensed'
  },
  fontFamilySelection: true,
  lineHeights: {
    1: '1',
    1.1: '1.1',
    1.15: '1.15',
    1.2: '1.2',
    1.25: '1.25',
    1.5: '1.5',
    Double: '2',
    2.1: '2.1',
    2.15: '2.15',
    2.2: '2.2',
    2.25: '2.25',
    2.5: '2.5'
  },
  colorsText: [
    '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
    '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
    '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
    '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
  ],
  listAdvancedTypes: true
}
export default configuration
